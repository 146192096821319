.page-spacing {
  margin-top: 150px;
}

.aboutme-topic {
  font-weight: 800;
}
.over-topic {
  font-weight: bold;
  color: var(--d_grey);
}

.aboutme-content {
  margin-bottom: 30px;
}

.profile-box {
  max-width: 100vw;
  max-height: 100%;
  border-radius: 30px;
}

.profile-pic {
  object-fit: cover;
  border-radius: 7rem;
}

.profile-pic-main-container {
  position: absolute;
  top: 100px;
  left: 100px;
  transform: rotate(30deg);
  -webkit-transform: rotate(30deg);
  -moz-transform: rotate(30deg);
  -o-transform: rotate(30deg);
  width: 300px;
  height: 300px;
  overflow: hidden;
}

.profile-pic-main {
  position: absolute;
  top: -100px;
  left: -50px;
  transform: rotate(-30deg);
  -webkit-transform: rotate(-30deg);
  -moz-transform: rotate(-30deg);
  -o-transform: rotate(-30deg);
  width: 300;
  height: 300px;
}
