.description-content {
  margin-top: auto;
  margin-bottom: auto;
  box-sizing: border-box;
  padding: 50px;
}

.banner {
  max-width: 110%;
  height: auto;
  object-fit: cover;
  object-position: center;
  margin-left: -20px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.img-box {
  /* border: solid 1px black; */
  overflow: hidden;
  grid: Mid;
  /* margin-top: 20px; */
  margin-bottom: 30px;
}

.daily-ui {
  grid-area: After-mid;
  margin-left: auto;
  margin-right: auto;
}

.topic {
  /* font-size: 40px; */
  font-weight: 800;
  /* color: var(--d_grey); */
  text-align: center;
}

.main {
  grid-area: Main;
}

.bg-setting {
  max-width: 110%;
  height: auto;
  object-fit: contain;
  object-position: center;
  margin-left: -20px;
}

.hero-box {
  overflow: hidden;
  grid-area: Header;
  min-height: 70vh;
  min-width: 100vw;
}

.footer-box {
  /* border: black 1px solid; */
  margin-top: 50px;
  margin-bottom: 50px;
}

.footer-content {
  margin-left: auto;
  margin-right: auto;
  font-weight: 800;
  color: var(--grey);
}

.text-align {
  text-align: center;
  color: var(--d_grey);
  font-weight: bold;
}

.icon-set {
  width: 30px;
  height: 30px;
  border-radius: 30px;
  margin-left: 5px;
  margin-right: 5px;
}

.linkedin-set {
  width: 30px;
  height: 30px;
  margin-left: 5px;
  margin-right: 5px;
}

.icon-box {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
