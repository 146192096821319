.box-grid {
  display: grid;
  grid-template-columns: 70% auto;
  grid-template-rows: 50% 50%;
  grid-template-areas:
    "bigbox box1"
    "bigbox box2";
  grid-gap: -10px;
}

.box {
  margin: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.box1 {
  grid-area: box1;
}

.box2 {
  grid-area: box2;
}

.big-box {
  grid-area: bigbox;
}

.img-fit {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  border-radius: 15px;
  /* box-shadow: 2px 6px 5px 1px rgba(0, 0, 0, 0.3); */
}

.img-max {
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
  border-radius: 25px;
  /* box-shadow: 2px 6px 5px 1px rgba(0, 0, 0, 0.3); */
}

.stack-spacing {
  margin-bottom: 50px;
  padding-bottom: 40px;
}

.sm-pic {
  padding: 20px;
}