/* @font-face {
  font-family: "DM Sans";
  src: url("../fonts/DMSans-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "DM Sans";
  src: url("../fonts/DMSans-BoldItalic.ttf") format("truetype");
  font-weight: 700;
  font-style: BoldItalic;
}

@font-face {
  font-family: "DM Sans";
  src: url("../fonts/DMSans-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: Italic;
}

@font-face {
  font-family: "DM Sans";
  src: url("../fonts/DMSans-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "DM Sans";
  src: url("../fonts/DMSans-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: Medium;
}
@font-face {
  font-family: "DM Sans";
  src: url("../fonts/DMSans-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: MediumItalic;
} */

/* @font-face {
  font-family: Noto Sans Thai;
  src: url("../fonts/NotoSansThai.ttf") format("truetype");
  unicode-range: U+00-FF, U+980-9FF;
} */
/* 
.App[lang="en"] {
  font-family: "DM Sans", sans-serif;
  font-size: calc(60% + 0.8vmin);
} */

:lang(en) {
  font-family: "DM Sans", sans-serif;
  /* font-size: calc(60% + 0.8vmin); */
}

:lang(th) {
  font-family: "Noto Sans Thai", sans-serif;
  /* font-size: calc(60% + 0.8vmin); */
}

/* fading route */
.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 200 ease-in-out;
}

:root {
  --grey: #acacac;
  --d_grey: #707070;
  --dd-grey: #393939;
  --white: #ffffff;
}
